import { ResultErrorTicket } from '@common/api/errors/types';
import { createHubspotTicketSchema, HubspotTicketPayload } from '@common/api/hotel/types';
import { ModalFooter } from '@common/mantine/components/modal-footer';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Modal, NumberInput, Select, Stack, Textarea, TextInput } from '@mantine/core';
import { useCreateHubspotTicket } from '@pages/Admin/ErrorTickets/hooks/useCreateHubspotTicket';
import { useAdminUsersList } from '@pages/Admin/RpgUsers/hooks/useAdminUser';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

type Props = {
  data?: ResultErrorTicket;
  onClose: () => void;
};

export const CreateHubspotTicketForm = ({ data, onClose }: Props) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<HubspotTicketPayload>({
    resolver: zodResolver(createHubspotTicketSchema)
  });
  const { adminUserList, isLoading } = useAdminUsersList();

  const { createHubspotTicket } = useCreateHubspotTicket();

  useEffect(() => {
    if (data) {
      const currentContent = getValues('properties.content') || '';
      const dealOwnerName = adminUserList?.find((item) => item.email === data?.responsible_person);

      const newContent = `${currentContent}
-----
Deal Owner: ${dealOwnerName?.first_name || ''} ${dealOwnerName?.last_name || ''}
  ${data?.reseller !== 'Not Assigned' ? `Reseller: ${data.reseller}` : ''}
`.trimEnd();

      setValue('properties.subject', data.hotel_name + `(${data.hotel})` + ': ');
      setValue('properties.label', data.pms_name);
      setValue('properties.components', data.tag);
      setValue('properties.rpg_id', data.hotel);
      if (!isLoading) {
        setValue('properties.content', newContent);
      }
    }
  }, [data, isLoading]);

  const onSubmit = (payload: HubspotTicketPayload) => {
    createHubspotTicket(payload);
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
        <Stack gap="lg">
          <Controller
            name="properties.subject"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                label="Ticket Name"
                withAsterisk
                placeholder="Ticket Name"
                size="md"
                variant="filled"
                error={errors.properties?.subject?.message}
              />
            )}
          />

          <Controller
            name="properties.label"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                label="Label"
                withAsterisk
                placeholder="Ticket Label"
                size="md"
                variant="filled"
                className="hidden"
                error={errors.properties?.label?.message}
              />
            )}
          />

          <Controller
            name="properties.components"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                label="Tag"
                placeholder="Ticket Tag"
                size="md"
                variant="filled"
                className="hidden"
              />
            )}
          />

          <Controller
            name="properties.hs_ticket_priority"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Priority"
                withAsterisk
                placeholder="Ticket Priority"
                size="md"
                variant="filled"
                data={[
                  { value: 'LOW', label: 'Low' },
                  { value: 'MEDIUM', label: 'Medium' },
                  { value: 'HIGH', label: 'High' }
                ]}
                error={errors.properties?.hs_ticket_priority?.message}
              />
            )}
          />

          <Controller
            name="properties.rpg_id"
            control={control}
            render={({ field }) => (
              <NumberInput
                {...field}
                label="RPG ID"
                withAsterisk
                placeholder="RPG ID"
                hideControls
                size="md"
                variant="filled"
                error={errors.properties?.rpg_id?.message}
              />
            )}
          />

          <Controller
            name="properties.content"
            control={control}
            render={({ field }) => (
              <Textarea
                {...field}
                label="Description"
                placeholder="Ticket Description"
                variant="filled"
                withAsterisk
                autosize
                disabled={isLoading}
                error={errors.properties?.content?.message}
              />
            )}
          />
        </Stack>
      </Modal.Body>

      <ModalFooter>
        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="filled" type="submit">
          Create Ticket
        </Button>
      </ModalFooter>
    </form>
  );
};
