import { createHubspotTicket } from '@common/api/hotel';
import { HubspotTicketPayload } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { useMutation } from '@tanstack/react-query';

export const useCreateHubspotTicket = () => {
  const { addNotification } = useNotificationsStore();

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (payload: HubspotTicketPayload) => {
      return createHubspotTicket(payload);
    },
    onSuccess: () => {
      addNotification('success', 'Create Hubspot Ticket Successfully');
    },
    onError: () => {
      addNotification('fail', 'Failed to Create Hubspot Ticket');
    }
  });

  return {
    createHubspotTicket: mutate,
    isSuccess,
    isLoading
  };
};
