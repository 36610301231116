import { Checkbox, Flex, Grid, SegmentedControl, Stack, Text } from '@mantine/core';
import { Input } from '@common/components/atoms/Input';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Controller, useFormContext } from 'react-hook-form';
import { z } from 'zod';
import {
  DerivationsV2Schema,
  editSchema
} from '@pages/Client/PricingStrategy/RoomSetup/common/formSchema';
import { Divider } from '@common/components/atoms/Divider';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { useHotelPmsDataMap } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelPmsDataMap';
import React, { useEffect, Fragment, useState } from 'react';
import { Rate } from '@common/api/hotel/types';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { isEmpty, sortBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { Icon } from '@common/components/foundations/icons';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { useGetRateDerivations } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useRateDerivation';
import { PmsProvider } from '@pages/Client/hooks/usePmsProvider';

export const DerivedRatesV2: React.FC = () => {
  const DerivedRatesV2Schema = editSchema.extend({
    derivedRates: DerivationsV2Schema
  });
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const [availableRates, setAvailableRates] = useState<Rate[]>([]);
  const {
    control,
    watch,
    setValue,
    reset,
    clearErrors,
    formState: { errors }
  } = useFormContext<z.infer<typeof DerivedRatesV2Schema>>();

  const { pmsMapping, derivedRates, pricingSetup } = watch();

  const [activeTabs, setActiveTabs] = useState<string[]>([]);

  const {
    rateDerivationList,
    query: { isFetching }
  } = useGetRateDerivations(pmsMapping.roomInPms);

  const { hotelPmsDataMap } = useHotelPmsDataMap();
  const isOccupancyBased = pmsMapping.priceType === 'occupancy_based';

  useEffect(() => {
    if (!rateDerivationList) return;

    reset({
      ...watch(),
      derivedRates: rateDerivationList.map((rate) => ({
        id: rate.target_rate_plan_id,
        derivedRateInPms: rate.target_rate_plan_id,
        derivation: rate.derivation,
        percentDerivation: !rate.is_absolute,
        productDerivation: rate.occupancy_derivation !== null,
        occupancyDerivation: rate.occupancy_derivation as number,
        baseRate: pricingSetup?.basePrice,
        minOccupancy: rate.configurations?.min_occ,
        maxOccupancy: rate.configurations?.max_occ,
        defaultMinOccupancy: hotelPmsDataMap?.mapped_data.find(
          (item) => item.id === pmsMapping.roomInPms
        )?.min_occupancy,
        defaultMaxOccupancy: hotelPmsDataMap?.mapped_data.find(
          (item) => item.id === pmsMapping.roomInPms
        )?.max_occupancy
      }))
    });
    // Initialize the activeTabs state based on percentDerivation value for each derived rate
    const initialActiveTabs = rateDerivationList.map((pricePerRate) =>
      pricePerRate.is_absolute ? 'absolute' : 'percent'
    );
    setActiveTabs(initialActiveTabs);
  }, [rateDerivationList]);

  useEffect(() => {
    if (pmsMapping.roomInPms !== 0) return;

    reset({
      ...watch(),
      derivedRates: []
    });

    setAvailableRates([]);
  }, [pmsMapping.roomInPms]);

  useEffect(() => {
    if (hotelPmsDataMap && rateDerivationList) {
      const newAvailableRates =
        hotelPmsDataMap.mapped_data
          .find((item) => item.id === pmsMapping.roomInPms)
          ?.rates.filter(
            (rate) =>
              rate.id !== pmsMapping.baseRateInPms &&
              !rateDerivationList.some((derivedRate) => derivedRate.target_rate_plan_id === rate.id)
          ) || [];

      setAvailableRates(newAvailableRates);
    }
  }, [hotelPmsDataMap, rateDerivationList, pmsMapping.roomInPms]);

  const isEviivo = hotelDetails?.pms_provider === PmsProvider.EVIIVO;
  const isHotSoft = hotelDetails?.pms_provider === PmsProvider.HOTSOFT;

  return (
    <div className="flex flex-col gap-y-6">
      {Array.from({ length: 12 }).map((_, index) => {
        const derivedRate = derivedRates?.[index];
        let currentRates = availableRates;

        if (derivedRate?.derivedRateInPms) {
          const matchedRate = hotelPmsDataMap?.mapped_data
            .find((item) => item.id === pmsMapping.roomInPms)
            ?.rates.find((rate) => rate.id === derivedRate.derivedRateInPms);
          if (matchedRate) {
            currentRates = availableRates.some((rate) => rate.id === matchedRate.id)
              ? availableRates
              : [...availableRates, matchedRate];
          }
        }

        // Check the previous derivedRateInPms field to determine if the current dropdown should be shown
        const shouldShowDropdown =
          index === 0 || (derivedRates?.[index - 1] && derivedRates?.[index - 1].derivedRateInPms);

        // Check if the next dropdown will be shown (for rendering the divider)
        const shouldShowNextDropdown =
          derivedRates?.[index] && derivedRates?.[index]?.derivedRateInPms;

        return (
          <Fragment
            key={
              isEmpty(derivedRates) || !derivedRate?.derivedRateInPms
                ? `no-set-derived-rates-${index}`
                : derivedRate?.id
            }
          >
            {shouldShowDropdown && (
              <div className="flex w-full flex-col gap-y-6 md:w-2/3">
                {isFetching ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  <Controller
                    control={control}
                    name={`derivedRates.${index}.derivedRateInPms`}
                    defaultValue={null}
                    render={({ field: { value, onChange, name } }) => (
                      <SelectDropdown
                        fullWidth
                        options={[
                          {
                            label: 'Not Priced',
                            value: null
                          },
                          ...sortBy(
                            currentRates
                              ?.filter(
                                (rate) =>
                                  !derivedRates?.some(
                                    (derivedRate) => derivedRate.derivedRateInPms === rate.id
                                  ) || value === rate.id
                              )
                              .map((rate) => ({
                                label: `${rate.name} (${rate.rate_id})`,
                                value: rate.id
                              })) || [],
                            'label'
                          )
                        ]}
                        hint="Derived Rate in PMS"
                        background="grey"
                        name={name}
                        value={value}
                        onChange={(selectedRate) => {
                          onChange(selectedRate);

                          if (!selectedRate) {
                            setValue(`derivedRates.${index}.derivation`, 0);
                            setValue(`derivedRates.${index}.percentDerivation`, false);
                            setValue(`derivedRates.${index}.productDerivation`, false);
                            setValue(`derivedRates.${index}.occupancyDerivation`, null);
                          }

                          if (value) {
                            const previousSelectedRate = currentRates.find(
                              (rate) => rate.id === value
                            );
                            if (previousSelectedRate) {
                              setAvailableRates((prev) => [...prev, previousSelectedRate]);
                            }
                          }

                          if (selectedRate) {
                            setAvailableRates((prev) =>
                              prev.filter((rate) => rate.id !== selectedRate)
                            );
                          }
                        }}
                      />
                    )}
                  />
                )}

                {derivedRate?.derivedRateInPms && (
                  <>
                    {isFetching ? (
                      <Skeleton className="h-6 w-full" />
                    ) : (
                      <Grid
                        align={errors.derivedRates?.[index]?.derivation ? 'center' : 'flex-end'}
                      >
                        <Grid.Col span={10}>
                          <Controller
                            control={control}
                            name={`derivedRates.${index}.derivation`}
                            defaultValue={0}
                            render={({
                              field: { value, onChange, name },
                              fieldState: { error, invalid }
                            }) => (
                              <Input
                                showClearButton={false}
                                leadingAddon={
                                  derivedRate?.percentDerivation
                                    ? '%'
                                    : CurrencyFormatter.currencySymbol()
                                }
                                type="number"
                                label="Derivation"
                                placeholder="Please enter derivation"
                                background="grey"
                                error={invalid}
                                helperMessage={
                                  invalid ? (
                                    <InputHelperMessage
                                      icon={<Icon.WarningOutline className="h-4 w-4" />}
                                      message={error?.message}
                                    />
                                  ) : null
                                }
                                name={name}
                                value={value}
                                onChange={onChange}
                              />
                            )}
                          />
                        </Grid.Col>
                        <Grid.Col span={2}>
                          <SegmentedControl
                            mb={errors.derivedRates?.[index]?.derivation ? 4 : 0}
                            data={[
                              { label: CurrencyFormatter.currencySymbol(), value: 'absolute' },
                              { label: t('%'), value: 'percent' }
                            ]}
                            value={activeTabs[index] || 'absolute'}
                            onChange={(value) => {
                              if (value === 'percent') {
                                setValue(`derivedRates.${index}.percentDerivation`, true);
                              } else {
                                setValue(`derivedRates.${index}.percentDerivation`, false);
                              }
                              const updatedTabs = [...activeTabs];
                              updatedTabs[index] = value;
                              setActiveTabs(updatedTabs);
                              clearErrors(`derivedRates.${index}.derivation`);
                            }}
                          />
                        </Grid.Col>
                      </Grid>
                    )}

                    {isEviivo ? (
                      isFetching ? (
                        <Skeleton className="h-6 w-full" />
                      ) : (
                        <Input
                          showClearButton={false}
                          type="number"
                          label="Reference Occupancy"
                          background="grey"
                          value={
                            currentRates.find((item) => derivedRate?.derivedRateInPms === item.id)
                              ?.default_occupancy
                          }
                          readOnly
                        />
                      )
                    ) : null}

                    <div className="flex flex-col gap-y-4">
                      {isFetching ? (
                        <Skeleton className="h-6 w-full" />
                      ) : (
                        <Controller
                          control={control}
                          name={`derivedRates.${index}.productDerivation`}
                          defaultValue={false}
                          render={({ field: { value, onChange, name } }) => (
                            <Checkbox
                              label={
                                isOccupancyBased
                                  ? t('Additional Per Person Derivation')
                                  : t('Additional Charge')
                              }
                              id={name}
                              name={name}
                              checked={value}
                              onChange={(e) => {
                                onChange(e.target.checked);
                                if (!e.target.checked) {
                                  setValue(`derivedRates.${index}.occupancyDerivation`, null);
                                }
                              }}
                            />
                          )}
                        />
                      )}

                      {derivedRate?.productDerivation ? (
                        <Controller
                          control={control}
                          name={`derivedRates.${index}.occupancyDerivation`}
                          defaultValue={0}
                          render={({
                            field: { value, onChange, name },
                            fieldState: { error, invalid }
                          }) => (
                            <Input
                              showClearButton={false}
                              leadingAddon={CurrencyFormatter.currencySymbol()}
                              type="number"
                              background="grey"
                              error={invalid}
                              helperMessage={
                                invalid ? (
                                  <InputHelperMessage
                                    icon={<Icon.WarningOutline className="h-4 w-4" />}
                                    message={error?.message}
                                  />
                                ) : null
                              }
                              name={name}
                              value={value || ''}
                              onChange={onChange}
                              trailingAddon={
                                <TooltipProvider delayDuration={75}>
                                  <Tooltip>
                                    <TooltipTrigger type="button">
                                      <Icon.Info className="h-5 w-5 fill-grey" />
                                    </TooltipTrigger>
                                    <TooltipContent side="bottom" className="max-w-xs text-left">
                                      {t(
                                        'Additional Per Person Derivation will be applied on top of the absolute or percentage derivation above.'
                                      )}
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              }
                            />
                          )}
                        />
                      ) : null}

                      {isHotSoft ? (
                        isFetching ? (
                          <>
                            <Skeleton className="h-6 w-full" />
                            <Skeleton className="h-6 w-full" />
                          </>
                        ) : (
                          <Stack gap="xs">
                            <Flex gap="sm" align="center">
                              <Text size="sm" c="gray.6">
                                {t('Occupancy Allowed')}
                              </Text>
                              <TooltipProvider delayDuration={75}>
                                <Tooltip>
                                  <TooltipTrigger type="button">
                                    <Icon.Info className="h-5 w-5 fill-grey" />
                                  </TooltipTrigger>
                                  <TooltipContent side="bottom" className="max-w-xs text-left">
                                    {t(
                                      'Prices will only be uploaded for the number of guests between the minimum and maximum value set here.'
                                    )}
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </Flex>
                            <Flex justify="space-between" align="center" gap="md">
                              <Controller
                                control={control}
                                name={`derivedRates.${index}.minOccupancy`}
                                defaultValue={0}
                                render={({
                                  field: { value, onChange, name },
                                  fieldState: { error, invalid }
                                }) => (
                                  <Input
                                    showClearButton={false}
                                    type="number"
                                    label={t('Minimum') as string}
                                    placeholder="Minimum occupancy"
                                    background="grey"
                                    error={invalid}
                                    helperMessage={
                                      invalid ? (
                                        <InputHelperMessage
                                          icon={<Icon.WarningOutline className="h-4 w-4" />}
                                          message={error?.message}
                                        />
                                      ) : null
                                    }
                                    name={name}
                                    value={value || ''}
                                    onChange={onChange}
                                  />
                                )}
                              />

                              <Controller
                                control={control}
                                name={`derivedRates.${index}.maxOccupancy`}
                                defaultValue={0}
                                render={({
                                  field: { value, onChange, name },
                                  fieldState: { error, invalid }
                                }) => (
                                  <Input
                                    showClearButton={false}
                                    type="number"
                                    label={t('Maximum') as string}
                                    placeholder="Maximum occupancy"
                                    background="grey"
                                    error={invalid}
                                    helperMessage={
                                      invalid ? (
                                        <InputHelperMessage
                                          icon={<Icon.WarningOutline className="h-4 w-4" />}
                                          message={error?.message}
                                        />
                                      ) : null
                                    }
                                    name={name}
                                    value={value || ''}
                                    onChange={onChange}
                                  />
                                )}
                              />
                            </Flex>
                          </Stack>
                        )
                      ) : null}
                    </div>
                  </>
                )}
              </div>
            )}

            {/* Only render the Divider if the next dropdown should be shown */}
            {shouldShowNextDropdown && index < 11 && <Divider />}
          </Fragment>
        );
      })}
    </div>
  );
};

export default DerivedRatesV2;
