import { ResultErrorTicket } from '@common/api/errors/types';
import { Modal } from '@mantine/core';
import { CreateHubspotTicketForm } from '@pages/Admin/ErrorTickets/components/CreateHubspotTicketForm';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  data?: ResultErrorTicket;
};

const CreateHubspotTicketModal = ({ isOpen, onClose, data }: Props) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal.Root
      opened={isOpen}
      onClose={() => {
        onClose();
      }}
      size="lg"
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header bg="white">
          <Modal.Title>Create Ticket</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>

        <CreateHubspotTicketForm onClose={handleClose} data={data} />
      </Modal.Content>
    </Modal.Root>
  );
};

export default CreateHubspotTicketModal;
